<template>
  <header
    class="header"
    :class="{ 'mobile-nav-active': mobileNavOpen }"
    v-if="!showCompactHeader"
  >
    <div class="header-top">
      <div class="grid">
        <div class="col-1-1 no-p">
          <div class="header-top-container flex justify-end">
            <div
              v-if="leafletUrl.length"
              @click="closeMobileNav()"
              class="flex justify-center align-center leaflet"
            >
              <button @click="downloadLeaflet()" class="menu-item btn-login">
                {{ leaflet }}
              </button>
            </div>

            <div
              @click="closeMobileNav()"
              class="flex justify-center align-center"
            >
              <button
                v-if="isAuthenticated"
                @click="logout()"
                class="menu-item btn-login"
              >
                {{ logoutLabel }}
              </button>
              <router-link v-else to="/login" class="menu-item btn-login">{{
                loginLabel
              }}</router-link>
            </div>

            <router-link
              :to="`/place-reservation/summary?orderId=${orderId}`"
              class="btn-cart flex justify-center align-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23">
                <path
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="50"
                  stroke-width="2"
                  d="M9 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM20 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                ></path>
              </svg>
            </router-link>

            <nav class="lang-nav flex align-center">
              <ul class="flex align-center">
                <li
                  v-for="(language, i) in languages"
                  :key="`language-${i}`"
                  @click="selectLanguage(language.number)"
                  :class="{ active: selectedLanguage === language.number }"
                >
                  {{ language.abbreviation }}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="header-main">
      <div class="grid grid-pad">
        <div class="col-1-1">
          <div
            class="header-main-container flex align-center sm-justify-space-between"
          >
            <div @click="closeMobileNav()" class="flex align-center">
              <router-link
                to="/"
                id="retailer-logo"
                :style="`background-image:url(${retailerLogoUrl})`"
              ></router-link>
            </div>
            <nav id="main-nav" :class="{ open: mobileNavOpen }">
              <ul>
                <li @click="closeMobileNav()">
                  <router-link to="/product-catalog">{{
                    productCatalog
                  }}</router-link>
                </li>
                <li @click="closeMobileNav()">
                  <router-link to="/faq">FAQ</router-link>
                </li>
                <li @click="closeMobileNav()">
                  <router-link to="/customer-service">{{
                    customerService
                  }}</router-link>
                </li>
                <li v-if="isAuthenticated" @click="closeMobileNav()">
                  <router-link :to="`/my-order/${orderId}`">{{
                    myOrder
                  }}</router-link>
                </li>
                <li v-if="isAuthenticated" @click="closeMobileNav()">
                  <router-link to="/my-account">{{ myAccount }}</router-link>
                </li>
              </ul>
            </nav>
            <div @click="closeMobileNav()" class="flex align-center">
              <a
                id="brand-logo"
                href="#"
                target="_blank"
                :style="`background-image:url(${brandLogoUrl})`"
              ></a>
            </div>
            <span class="spacer mobile"></span>
            <button
              id="mobile-nav-trigger"
              @click="mobileNavOpen = !mobileNavOpen"
              :class="{ active: mobileNavOpen }"
            >
              <font-awesome-icon icon="bars" v-show="!mobileNavOpen" />
              <font-awesome-icon icon="times" v-show="mobileNavOpen" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>

  <header v-else>
    <div class="header-main compact">
      <div class="grid grid-pad">
        <div class="col-1-1">
          <div
            class="header-main-container flex align-end sm-justify-space-between"
          >
            <nav id="main-nav" :class="{ open: mobileNavOpen }">
              <ul>
                <li @click="closeMobileNav()">
                  <router-link to="/product-catalog">{{
                    productCatalog
                  }}</router-link>
                </li>
                <li @click="closeMobileNav()">
                  <router-link to="/faq">FAQ</router-link>
                </li>
                <li @click="closeMobileNav()">
                  <router-link to="/customer-service">{{
                    customerService
                  }}</router-link>
                </li>
                <li v-if="isAuthenticated" @click="closeMobileNav()">
                  <router-link :to="`/my-order/${orderId}`">{{
                    myOrder
                  }}</router-link>
                </li>
                <li v-if="isAuthenticated" @click="closeMobileNav()">
                  <router-link to="/my-account">{{ myAccount }}</router-link>
                </li>
              </ul>
            </nav>

            <span class="spacer mobile"></span>
            <button
              id="mobile-nav-trigger"
              @click="mobileNavOpen = !mobileNavOpen"
              :class="{ active: mobileNavOpen }"
            >
              <font-awesome-icon icon="bars" v-show="!mobileNavOpen" />
              <font-awesome-icon icon="times" v-show="mobileNavOpen" />
            </button>

            <div class="header-top-container flex justify-end">
              <div
                v-if="leafletUrl.length"
                @click="closeMobileNav()"
                class="flex justify-center align-center leaflet"
              >
                <button @click="downloadLeaflet()" class="menu-item btn-login">
                  {{ leaflet }}
                </button>
              </div>

              <div
                @click="closeMobileNav()"
                class="flex justify-center align-center"
              >
                <button
                  v-if="isAuthenticated"
                  @click="logout()"
                  class="menu-item btn-login"
                >
                  {{ logoutLabel }}
                </button>
                <router-link
                  @click="closeMobileNav()"
                  v-else
                  to="/login"
                  class="menu-item btn-login"
                >
                  {{ loginLabel }}
                </router-link>
              </div>

              <router-link
                :to="`/place-reservation/summary?orderId=${orderId}`"
                class="btn-cart flex justify-center align-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23">
                  <path
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="50"
                    stroke-width="2"
                    d="M9 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM20 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                  ></path>
                </svg>
              </router-link>

              <nav class="lang-nav flex align-center">
                <ul class="flex align-center">
                  <li
                    v-for="(language, i) in languages"
                    :key="`language-${i}`"
                    @click="selectLanguage(language.number)"
                    :class="{ active: selectedLanguage === language.number }"
                  >
                    {{ language.abbreviation }}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',
  data() {
    return {
      mobileNavOpen: false,
      selectedLanguage: 4,
    }
  },

  computed: {
    ...mapGetters([
      'leafletUrl',
      'leaflet',
      'isAuthenticated',
      'languages',
      'brandLogoUrl',
      'retailerLogoUrl',
      'productCatalog',
      'customerService',
      'myOrder',
      'myAccount',
      'showCompactHeader',
    ]),
    ...mapGetters({
      orderId: 'getOrderId',
      loginLabel: 'login',
      logoutLabel: 'logout',
    }),
  },

  methods: {
    closeMobileNav() {
      this.mobileNavOpen = false
    },

    selectLanguage(languageId) {
      this.selectedLanguage = languageId
      this.$store.dispatch('selectLanguage', languageId)
    },

    logout() {
      this.closeMobileNav()
      this.$store.dispatch('logout')
    },

    downloadLeaflet() {
      const fileLink = document.createElement('a')
      fileLink.setAttribute('target', '_blank')
      fileLink.href = this.leafletUrl
      fileLink.setAttribute('download', this.leafletUrl)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';
@import '@/global-styles/mixins';

header {
  background-color: $white;
  border-bottom: 1px solid $grey-light-2;

  &-container {
    min-height: 6rem;
  }

  .menu-item {
    padding: 2.3rem 1.8rem;
    color: $black;
    position: relative;
    font-size: 1.2rem;
    text-transform: uppercase;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      height: 0;
      background: $white;
      width: 100%;
      transition: all 0.2s ease;
    }

    &.router-link-active::after,
    &:hover::after {
      background: $color-primary;
      height: 4px;
    }
  }

  button.menu-item.btn-login {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .btn-cart {
    border-left: 1px solid $grey-light-2;
    padding: 1.8rem;

    svg {
      stroke: $color-primary;
    }
  }

  .lang-nav {
    padding: 0 1.5rem 0 0;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 1.2rem;
        font-weight: 400;
        color: $black;
        text-transform: uppercase;

        padding: 0.5rem 0.4rem 0.5rem 0.1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: $offblack;
        cursor: pointer;

        &.active {
          font-weight: 700;
        }

        &::before {
          content: '/';
          margin-right: 0.2rem;
        }

        &:first-of-type::before {
          display: none;
        }
      }
    }
  }
}

.header-area {
  border-bottom: 1px solid $grey-light-2;
  margin-bottom: 4rem;
}

#mobile-nav-trigger {
  float: right;
  width: 6rem;
  height: 6rem;
  font-size: 2.4rem;
  background: none;
  border: none;
  color: $color-primary;
  display: none;

  &.active {
    position: fixed;
    top: 6rem;
    right: 0rem;
    z-index: 20;
    margin: 0;
    color: $offblack;
  }
}

.header-main {
  position: relative;
  background: $white;

  &-container {
    min-height: 8.6rem;
  }
}

#brand-logo,
#retailer-logo {
  display: inline-block;
  height: 4.2rem;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  text-indent: -9999em;
  margin: 0;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

#retailer-logo {
  width: 14rem;
  background-position: center left !important;
  margin-right: 3rem;
}

#brand-logo {
  width: 12rem;
  background-position: center right !important;
}

.header .spacer {
  flex-grow: 1;
}

.header .spacer.mobile {
  display: none;
}

#main-nav {
  display: inline-block;
  flex-grow: 1;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 0.5rem;

      a {
        color: $offblack;
        font-size: 1.8rem;
        font-weight: 300;
        padding: 3.2rem 1rem;
        min-width: 8rem;
        text-align: center;
        display: block;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          height: 0;
          background: $white;
          width: 100%;
          transition: all 0.2s ease;
        }

        &.router-link-active::after,
        &:hover::after {
          background: $color-primary;
          height: 4px;
        }
      }
    }
  }
}

header .compact {
  .menu-item {
    padding: 3.2rem 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  #main-nav ul li a,
  .compact .menu-item {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 900px) {
  #main-nav ul li a,
  .compact .menu-item {
    font-size: 1.2rem;
    padding: 3.2rem 0rem;
  }
}

@media only screen and (max-width: 768px) {
  .header.mobile-nav-active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .header-main .header-main-container {
    min-height: 6rem;
  }

  .header {
    .spacer.mobile {
      display: inline-block;
    }

    .spacer.desktop {
      display: none;
    }
  }

  #retailer-logo {
    margin-right: 1.5rem;
    width: 8rem;
    position: absolute;
    top: -85%;
    left: 2rem;
  }

  #brand-logo {
    width: 8rem;
  }

  #mobile-nav-trigger {
    display: inline-block;
  }

  #main-nav {
    padding: 0 0 3rem;
    position: fixed;
    left: 0;
    top: 0;
    min-height: calc(100vh - 12rem);
    width: 100%;
    background: $white;
    z-index: 15;
    text-align: center;
    backface-visibility: hidden;
    overflow: auto;
    transform: translateY(-100%);
    transition: all 0.3s ease;

    &.open {
      transform: translateY(17%);
    }

    ul li {
      display: block;

      a {
        padding: 2rem 1.5rem;
        font-weight: 700;
        border-bottom: 1px solid $grey-light-2;

        &.active {
          color: $color-primary;
        }

        &::after {
          display: none;
        }
      }

      &:first-of-type a {
        border-top: 1px solid $grey-light-2;
      }
    }
  }

  #mobile-nav-trigger {
    display: block;
  }

  .compact {
    #main-nav.open {
      transform: translateY(70px);
    }
    .header-main-container {
      justify-content: flex-end;
    }
    #mobile-nav-trigger {
      order: 2;
    }
  }
}

@media only screen and (max-width: 480px) {
  #mobile-nav-trigger {
    margin-right: -2rem;
    transition: color 0.2s ease;
  }
}
</style>
