<template>
    <footer class="footer">
        <div class="grid grid-pad">
            <div class="col-1-1">
                <span>&copy; {{currentYear}} Bosch</span> &nbsp;|&nbsp;
                <router-link to="/terms">{{termsAndConditions}}</router-link> &nbsp;|&nbsp;
                <router-link to="/privacy">{{privacyPolicy}}</router-link> &nbsp;|&nbsp;
                <router-link to="/cookie-policy">{{cookiePolicy}}</router-link>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppFooter',
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        ...mapGetters(['termsAndConditions', 'privacyPolicy', 'cookiePolicy'])
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.footer {
    text-align: center;
    padding: 5rem 1rem;
    font-weight: 300;
    background: $white;
    font-size: 1.6rem;

    @media only screen and (max-width: 768px) {
        padding: 3rem 0rem;
        font-size: 2.6vw;
    }

    a {
        transition: all .25s ease;
        color: inherit;
        font-size: inherit;
        
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>